export const allowedSpecialCharacters = /[!@#$%&*_+-.]+/;
export const allowedOneNumber = /\d/;
export const allowedOneUppercase = /[A-Z]+/;
export const allowedOneLowercase = /[a-z]+/;
export const allowedOneWord = /^[a-zA-ZáÁéÉíÍÓóúÚÑñ]+$/i;
export const allowedWord = /[a-zA-ZáÁéÉíÍÓóúÚÑñ]+/g;
export const allowedDate =
  /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
export const allowedDobDate = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
export const allowedEmail = /\S+@\S+\.\S+/;
export const allowedNumbers = /^[0-9]+$/;
export const allowenNumbers = /[0-9]/g;
export const allowenNoDigits = /\D/g;
export const allowedDash = /-/g;
export const allowedMoreDigits = /^\d{4}$/;
export const allowedDate2 = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/g;
export const allowedNumbersDash = /^\d{5}$/;
/*
 * Expression to validate phone number
 * Examples:
 *   +1 (123) 456-7890
 *   (555) 555-5555
 *   +44 20 1234 5678
 */
export const allowedPhoneNumberFormat =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const allowedPhoneNumber = /(\d{0,3})(\d{0,3})(\d{0,4})/;
export const allowedUpperCase = /([A-Z])/g;
export const allowedCompoundNames = /^[a-zA-ZáÁéÉíÍÓóúÚÑñ\s.]*$/;
export const allowedAlphanumeric = /^[a-zA-Z0-9]{11}$/;
export const allowsNumbersAccountNumber = /^\d{8,17}$/;
export const allowsNumbersRoutingNumber = /^\d{9}$/;
export const allowedOnlyCharacters = /^[a-zA-Z]*$/;
export const bestFXLessFeePayers = /^(Uniteller( [a-zA-Z])?|SPEI|BanCoppel|Banco Azteca)$/;
