import { useTranslation } from "react-i18next";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { ZelleColorSpan } from "../../Text/Text.styled";
import { StepLogo } from "./QuoteSummary.styled";
import IconFont from "../../IconFont";
import SendolaShortLogo from "../../../Assets/Img/s-rounded.png";
import Button from "../../Button";
import { useState } from "react";
import { CopyTextToClipboard } from "../../../Utilities/CopyTextToClipboard";
import { zelleSendolaAccount } from "../../../Constants/Zelle";
import CurrencyService from "../../../Services/CurrencyService";

interface ZelleInstructionsProps {
  amount: number;
  reference: string;
}

export const ZelleInstructions = ({ amount, reference }: ZelleInstructionsProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const [copiedTextEmail, setCopiedTextEmail] = useState(false);
  const [copiedTextAmount, setCopiedTextAmount] = useState(false);
  const [copiedTextReference, setCopiedTextReference] = useState(false);

  const copyTextEmail = () => {
    setCopiedTextEmail(true);
    CopyTextToClipboard(zelleSendolaAccount);
    setTimeout(() => {
      setCopiedTextEmail(false);
    }, 1000);
  };

  const copyTextAmount = () => {
    setCopiedTextAmount(true);
    CopyTextToClipboard(amount.toString());
    setTimeout(() => {
      setCopiedTextAmount(false);
    }, 1000);
  };

  const copyTextReference = () => {
    setCopiedTextReference(true);
    CopyTextToClipboard(reference.toString());
    setTimeout(() => {
      setCopiedTextReference(false);
    }, 1000);
  };

  return (
    <FlexContainer direction="column" gap="12px">
      <Text size="default" align="left" weight={600}>
        <ZelleColorSpan dangerouslySetInnerHTML={{ __html: tSendMoney("paymentMethods.zelle.finishText") }} />
      </Text>
      <FlexContainer direction="column" gap="16px">
        <FlexContainer alignItems="center" gap="8px">
          <StepLogo>
            <img src={SendolaShortLogo} width={18} />
          </StepLogo>

          <FlexContainer alignItems="center" gap="2px" flexWrap>
            <Text align="left" display="inline" size="default">
              <ZelleColorSpan dangerouslySetInnerHTML={{ __html: t("Zelle.finishInstructions.step1.description") }} />
            </Text>
            <FlexContainer w="auto" gap="8px">
              <Text align="left" color="zelle" size={0.5} weight={600}>
                {zelleSendolaAccount}
              </Text>
              <Button sizeButton="fit" variant="transparent" padding="0px" onClick={copyTextEmail}>
                <FlexContainer alignItems="center" gap="2.5px">
                  <IconFont name={copiedTextEmail ? "circle-check" : "copy"} color="zelle" size="small" />
                  <Text as="span" color="zelle" weight={600}>
                    {` ${t("global.copy")}`}
                  </Text>
                </FlexContainer>
              </Button>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer alignItems="center" gap="8px">
          <StepLogo>
            <IconFont name="currency-dollar" color="zelle" size="default" />
          </StepLogo>
          <FlexContainer alignItems="center" gap="2px" flexWrap>
            <Text align="left" display="inline" size="default">
              <ZelleColorSpan dangerouslySetInnerHTML={{ __html: t("Zelle.finishInstructions.step2.description") }} />
            </Text>
            <FlexContainer w="auto" gap="8px">
              <Text align="left" color="zelle" size={0.5} weight={600}>
                {CurrencyService.formatNumberToCurrency(amount)} USD
              </Text>
              <Button sizeButton="fit" variant="transparent" padding="0px" onClick={copyTextAmount}>
                <FlexContainer alignItems="center" gap="2.5px">
                  <IconFont name={copiedTextAmount ? "circle-check" : "copy"} color="zelle" size="small" />
                  <Text as="span" color="zelle" weight={600}>
                    {` ${t("global.copy")}`}
                  </Text>
                </FlexContainer>
              </Button>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer alignItems="center" gap="8px">
          <StepLogo>
            <IconFont name="message" color="zelle" size="default" />
          </StepLogo>
          <FlexContainer alignItems="center" gap="2px" flexWrap>
            <Text align="left" display="inline" size="default">
              <ZelleColorSpan dangerouslySetInnerHTML={{ __html: t("Zelle.finishInstructions.step3.description") }} />
            </Text>
            <FlexContainer w="auto" gap="8px">
              <Text align="left" color="zelle" size={0.5} weight={600}>
                {reference}
              </Text>
              <Button sizeButton="fit" variant="transparent" padding="0px" onClick={copyTextReference}>
                <FlexContainer alignItems="center" gap="2.5px">
                  <IconFont name={copiedTextReference ? "circle-check" : "copy"} color="zelle" size="small" />
                  <Text as="span" color="zelle" weight={600}>
                    {` ${t("global.copy")}`}
                  </Text>
                </FlexContainer>
              </Button>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      <Text size="default" align="left" weight={600}>
        <ZelleColorSpan dangerouslySetInnerHTML={{ __html: tSendMoney("paymentMethods.zelle.finishWA") }} />
      </Text>
    </FlexContainer>
  );
};
