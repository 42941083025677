import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeneficiarySelector, BeneficiarySlice } from "../../../Redux/Beneficiary/Beneficiary.slice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../Hooks/useModal";
import Layout from "../../../Components/Layout";
import { ThemeProvider } from "styled-components";
import theme from "../../../Contexts/ThemeProvider";
import { scrollTopWindow } from "../../../Utilities/ScrollTopWindow";
import { Column, Container, Row } from "../../../Components/Grid";
import { NewBeneficiaryHead } from "../Components/NewBeneficiaryHead/NewBeneficiaryHead.component";
import { ProfileForm } from "../Components/ProfileForm/ProfileForm.component";
import { FormProvider, useForm } from "react-hook-form";
import { ContactForm } from "../Components/ContactForm/ContactForm.component";
import Button from "../../../Components/Button";
import { AddressForm } from "../Components/AddressForm/AddressForm.component";
import { useBeneficiaryAdditionalFields } from "../../../Hooks/useBeneficiaryAdditionalFields";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { DynamicInput } from "../../../Components/DynamicInput";
import isEmpty from "lodash.isempty";
import { getStatusPersonKYC, PersonsKYCSelector } from "../../../Redux/PersonsKYC";
import { BeneficiaryForm } from "../../../Modules/Beneficiary/domain/BeneficiaryForm";
import { AppDispatch } from "../../../Redux/Store";
import { GET_PERSON_ID_FULFILLED } from "../../../Constants/Person";
import { DashboardSelector } from "../../../Redux/Dashboard/Dashboard.slice";
import { Form } from "../../../Components/Form/Form.component";

export const CreateBeneficiary = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    data: { countrySelected: beneficiaryCountrySelected, newBeneficiary },
  } = useSelector(BeneficiarySelector);
  const { previousView } = useSelector(DashboardSelector);
  const {
    data: { countryDestination: quoteCountrySelected, currentPaymentDestination },
  } = useSelector(QuotationSelector);
  const { data: personKYCData } = useSelector(PersonsKYCSelector);

  //? we always prioritize country selected in beneficiary flow, then we check if there is one country set in quotation
  const [countrySelected, setCountrySelected] = useState(
    newBeneficiary?.country || beneficiaryCountrySelected?.countryCode || quoteCountrySelected
  );
  const subdivisionSelected = newBeneficiary?.subnational;
  const citySelected = newBeneficiary?.city;

  const { modal, showModal } = useModal();
  const form = useForm({ mode: "all", values: newBeneficiary });
  const { getValues, handleSubmit, trigger } = form;
  const { additionalFields: beneficiaryAdditionalFields, isLoading: loadingAdditionalFields } =
    useBeneficiaryAdditionalFields({
      country: countrySelected || "",
      destinationSelected: currentPaymentDestination,
      transferType: "CPU",
      includeBranchFields: false,
      form,
    });
  const {
    additionalFieldsAppriza,
    fetchAdditionalFieldsAppriza,
    isLoading: loadingAdditionalAppriza,
  } = useBeneficiaryAdditionalFields({
    country: "",
  });

  const loading = loadingAdditionalFields || loadingAdditionalAppriza;

  const processSubmit = handleSubmit(async (data) => {
    dispatch(BeneficiarySlice.actions.setNewBeneficiary(data));
    trigger();
    let personId = personKYCData?.personVeriff?.personId;
    if (!personId) {
      const userAuthPerson = await dispatch(getStatusPersonKYC());
      if (userAuthPerson.type === GET_PERSON_ID_FULFILLED) {
        personId = userAuthPerson.payload?.toString();
      }
    }
    const date = data.dateOfBirth.split("/");
    if (personId) {
      const formData: BeneficiaryForm = {
        personId,
        recipient: {
          firstName: data.firstName?.trim(),
          middleName: data.middleName?.trim(),
          lastName: data.lastName?.trim(),
          alias: data.alias?.trim(),
          dateOfBirth: `${date[2]}-${date[1]}-${date[0]}`,
          phoneNumber: `${data.phoneCountryCode}${data.phoneNumber?.trim()}`,
          email: data.email ? data.email.trim() : null,
          addressType: "Ho",
          address: data.address1?.trim(),
          addressExtra: data.address2,
          city: data.city,
          subnational: `${data.subnational}`,
          country: data.country,
          countryOfBirth: data.country,
          nationality: data.country,
          postalCode: data.postalCode?.trim(),
          gender: "M",
        },
      };

      formData.recipient.additionalField = {
        additionalFieldInfo: additionalFieldsAppriza.map((field) => ({
          fieldBelongsTo: "",
          fieldName: field.fieldName,
          fieldValue: getValues(field.fieldName),
        })),
      };

      dispatch(
        BeneficiarySlice.actions.setBeneficiaryToSave({
          ...formData,
          bankName: currentPaymentDestination?.destination,
          rail: currentPaymentDestination?.rail,
        })
      );
      navigate("/accounts", {
        state: {
          view: "create",
        },
      });
    } else {
      navigate(-1);
    }
  });

  useEffect(() => {
    if (!countrySelected) {
      navigate("/beneficiaries/create/country-selection");
    } else {
      fetchAdditionalFieldsAppriza(countrySelected);
    }
  }, [countrySelected]);

  useEffect(() => {
    scrollTopWindow();
    showModal({
      modalType: "bottomModal",
      title: t("Beneficiaries.InfoMatchTitle"),
      message: t("Beneficiaries.InfoMatchDescription"),
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={loading} roundedContent={false}>
        <FormProvider {...form}>
          <Form onSubmit={processSubmit}>
            <Container maxWidth="393px">
              <Row>
                <Column span={12} px={3} mt={2} mb={3}>
                  <NewBeneficiaryHead
                    showTitle
                    onClose={() =>
                      navigate("/dashboard", {
                        state: {
                          activeTab: previousView === "dashboard" ? "dashboard" : "recipients",
                        },
                      })
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <ProfileForm />
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <ContactForm countrySelected={countrySelected} />
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <AddressForm
                    countrySelected={countrySelected}
                    subdivisionSelected={subdivisionSelected}
                    citySelected={citySelected}
                    onSelectCountry={setCountrySelected}
                  />
                </Column>
              </Row>
              {!isEmpty(beneficiaryAdditionalFields) && (
                <Row>
                  <Column span={12} px={3} mb={2}>
                    <hr />
                  </Column>
                  <Column span={12} px={3} mb={2}>
                    {beneficiaryAdditionalFields.map((field) => (
                      <DynamicInput key={field.fieldName} {...field} />
                    ))}
                  </Column>
                </Row>
              )}
              {!isEmpty(additionalFieldsAppriza) && (
                <Row>
                  <Column span={12} px={3} mb={2}>
                    <hr />
                  </Column>
                  <Column span={12} px={3} mb={2}>
                    {additionalFieldsAppriza.map((field) => (
                      <DynamicInput key={field.fieldName} {...field} />
                    ))}
                  </Column>
                </Row>
              )}
              <Row>
                <Column span={12} px={3} mb={4}>
                  <Button
                    variant="primary"
                    sizeButton="full"
                    text={t("global.continue")}
                    sizeText="medium"
                    iconButton="arrowRight"
                    colorIcon="white"
                    sizeIcon="large"
                    padding="8px"
                    type="submit"
                  />
                </Column>
              </Row>
            </Container>
            {modal}
          </Form>
        </FormProvider>
      </Layout>
    </ThemeProvider>
  );
};
