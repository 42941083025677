import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";

// Own components
import Footer from "../Footer/Footer.component";
import Header from "../Header";
import Link from "../Link";
import IconFont from "../IconFont";
import FloatingZelleButton from "../FloatingZelleButton";
import FloatingHelpButton from "../FloatingHelpButton";
import { FloatingCouponsButton } from "../FloatingCouponsButton/FloatingCouponsButton.component";
import HelpLinks from "../HelpLinks";

// Interface
import { PropsLayout } from "../../Models/Layout";

// Own redux
import { CouponsSelector } from "../../Redux/Coupons/Coupons.slice";

// Own styles
import { ContainerFluid, HeaderContainer, Content, FooterContainer, Return } from "./Layout.styled";
import Loader from "../Loader";
import { DifferenceTime } from "../../Utilities/DifferenceTime";
import { CouponsModal } from "../CouponsModal/CouponsModal.component";

const Layout = ({
  path,
  pathState,
  textLink,
  show = true,
  showHelpBtn = true,
  rewardsFeature,
  loading,
  loadingMsg,
  fullWidthContent,
  roundedContent = true,
  children,
  translatedContent,
  showHeaderContent = true,
  transactionList,
}: PropsLayout) => {
  const navigate = useNavigate();
  const [showCouponsModal, setShowCouponsModal] = useState(false);
  const { couponsGrouped } = useSelector(CouponsSelector).data;
  const transactionsZelle =
    transactionList?.results?.filter(
      (transaction) =>
        transaction.fundType === "Zelle" && transaction.status === "Funds" && DifferenceTime(transaction?.processedAt)
    ) || [];

  const transactionsVenmo =
    transactionList?.results?.filter(
      (transaction) =>
        transaction.fundType === "Venmo" && transaction.status === "Funds" && DifferenceTime(transaction?.processedAt)
    ) || [];

  const isFirstTransaction = useMemo(() => {
    if (transactionList?.results && transactionList?.results?.length !== 0) return false;

    return true;
  }, [transactionList]);

  return (
    <>
      {loading && <Loader full message={loadingMsg} />}
      <ContainerFluid $show={show} fullWidthContent={fullWidthContent} roundedContent={roundedContent}>
        <HeaderContainer className="header">
          <Header showContent={showHeaderContent} />
        </HeaderContainer>
        {textLink && (
          <Return>
            <IconFont name="chevron-left" color="white" size="small" />
            <Link
              {...(path
                ? {
                    href: pathState ? "#" : path,
                    onClick: pathState
                      ? (e) => {
                          e.preventDefault();
                          navigate(path, { state: pathState });
                        }
                      : undefined,
                  }
                : { href: "#", onClick: () => navigate(-1) })}
              size={0.5}
            >
              {textLink}
            </Link>
          </Return>
        )}
        <Content className="main-content" withPadding={roundedContent} translated={translatedContent}>
          {children}
        </Content>

        {(transactionsZelle?.length || transactionsVenmo?.length) && <FloatingZelleButton />}

        {showHelpBtn && (
          <FloatingHelpButton>
            <HelpLinks />
          </FloatingHelpButton>
        )}

        {rewardsFeature && !isFirstTransaction && couponsGrouped?.length > 0 && (
          <FloatingCouponsButton onClick={() => setShowCouponsModal(true)} />
        )}

        <FooterContainer className="footer">
          <Footer />
        </FooterContainer>

        <CouponsModal show={showCouponsModal} coupons={couponsGrouped} handleClose={() => setShowCouponsModal(false)} />
      </ContainerFluid>
    </>
  );
};

export default Layout;
