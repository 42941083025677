/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppDispatch } from "../Redux/Store";
import { QuotationSlice } from "../Redux/Quotation";

import { Currency } from "../Modules/Common/domain/Currency";
import { getQuoteWithFees, getQuoteWithFeesAndTiers } from "../Modules/Quote/application/getQuote";

import { createApiQuoteRepository } from "../Modules/Quote/infrastructure/ApiQuoteRepository";
import {
  QuoteDeliveryMethod,
  QuoteDeliveryMethodCode,
  QuotePaymentMethodDestination,
  getQuoteDeliveryMethod,
} from "../Modules/Quote/domain/QuoteDeliveryMethod";
import {
  QuoteChannel,
  QuoteOption,
  QuoteSelectedValue,
  getQuoteMethodDestination,
} from "../Modules/Quote/domain/QuoteSelected";
import { getCountryDestination, getQuoteCountryDestinationCode } from "../Modules/Quote/domain/QuoteCountryDestination";
import { useStatus } from "./useStatus";
import { useModal } from "./useModal";
import { Errors } from "../Modules/Common/domain/Errors";
import { PersonsKYCSelector } from "../Redux/PersonsKYC";
import { useDestination } from "./useDestination";
import { SelectOption } from "../Models/Select";
import { Destination } from "../Modules/Common/domain/Destination";
import { countrySelector } from "../Redux/Country/Country.slice";
import { IconsFontType } from "../Models/IconInterface";
import { getPaymentMethods } from "../Modules/Common/application/getPaymentMethods";
import { createApiPaymentMethodRepository } from "../Modules/Common/infraestructure/ApiPaymentMethodRepository";
import { getAccountDetails } from "../Modules/PaymentOrders/application/plaidPaymentOrder";
import { PaymentMethod, PaymentMethods, PlaidAccount } from "../Modules/Common/domain/PaymentMethods";
import { selectorLanguage } from "../Redux/Translate";
import { PlaidPaymentOrderRepository } from "../Modules/PaymentOrders/infrastructure/ApiPaymentWithPlaidRepository";
import { useCoupons } from "./useCoupons";
import { CouponGroup, getNextCoupon, VerifyCouponPayloadShort } from "../Modules/Rewards/domain/Coupon";
import isEmpty from "lodash.isempty";
import { paymentOrderSlice } from "../Redux/PaymentOrder";
import { QuotationSelector } from "../Redux/Quotation/Quotation.slice";
import { checkDestinationAmountRange } from "../Modules/Quote/domain/Quote";
import { useLocation } from "react-router-dom";

type DestinationByCountry = {
  country: string;
  method: QuoteDeliveryMethodCode;
  destinations: (SelectOption & Destination)[];
  sponsorBank?: string;
};

export const useQuote = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    countryDestination,
    currentPaymentDestination,
    paymentMethods,
    paymentMethodSelected,
    currentPaymentMethod,
    couponToApply,
    currentChannel,
    quotesPerChannel,
  } = useSelector(QuotationSelector).data;
  const { isLoading: quoteIsLoading, hasError, error, setError, startRequest, endRequest } = useStatus();
  const [sendToSelectOptions, setSendToSelectOptions] = useState<(QuoteOption & Destination)[]>([]);
  const { countries } = useSelector(countrySelector);
  const { language } = useSelector(selectorLanguage);
  const quoterValue = useRef<Partial<QuoteSelectedValue>>({
    currentQuote: currentDeliveryMethodQuote,
    amount: currentQuotation?.amount || 500,
    currency: countries.find((c) => c.countryCode === "MEX") ? Currency.MXN : countries[0]?.defaultCurrency || null,
    country: countries.find((c) => c.countryCode === "MEX") ? "MEX" : countries[0]?.countryCode || null,
    deliveryMethod: currentDeliveryMethodQuote?.deliveryMethodCode || "D2B",
    sendTo: currentPaymentDestination?.id || null,
    channel: null,
  });
  const destinationsByCountryRef = useRef<DestinationByCountry[]>([]);
  const { modal: quoteModal, showModal: showQuoteModal } = useModal();
  const [tModals] = useTranslation("modals");
  const personVeriff = useSelector(PersonsKYCSelector).data?.personVeriff;
  const personVerifyKYC = useSelector(PersonsKYCSelector).data?.personVerifyKYC;
  const { isLoading: destinationIsLoading, getDestinationsByCountry } = useDestination();
  const { couponsGrouped, verifyQuoteCoupon } = useCoupons();
  const isLoading = quoteIsLoading || destinationIsLoading;

  const deliveryMethodsSelectOptions = [
    {
      value: "D2B",
      label: t("Quote.DirectToBank"),
      iconName: "safe" as IconsFontType,
      superscript: "2",
    },
    {
      value: "CPU",
      label: t("Quote.CashPickUp"),
      iconName: "hand-coin" as IconsFontType,
      superscript: "3",
    },
  ];

  const resetQuote = () => {
    dispatch(QuotationSlice.actions.resetState());
    quoterValue.current = {
      amount: 500,
      currency: countries.find((c) => c.countryCode === "MEX") ? Currency.MXN : countries[0]?.defaultCurrency,
      country: countries.find((c) => c.countryCode === "MEX") ? "MEX" : countries[0]?.countryCode,
      deliveryMethod: "D2B",
      sendTo: null,
    };
  };

  const handleQuotationChange = async (quoteValues: QuoteSelectedValue) => {
    if (quoteValues.amount && quoteValues.currency && quoteValues.deliveryMethod) {
      const countryDestinationCode =
        quoteValues.country || getQuoteCountryDestinationCode(quoteValues.currency, countries);
      if (countryDestinationCode !== countryDestination) {
        dispatch(QuotationSlice.actions.setCountryDestination(countryDestinationCode));
      }

      const deliveryMethodDestinations = await getDeliveryMethodDestinations({
        deliveryMethod: quoteValues.deliveryMethod,
        countryDestination: countryDestinationCode,
        sponsorBank: (paymentMethodSelected as PaymentMethod)?.sponsorBank,
      });
      setSendToSelectOptions(deliveryMethodDestinations);

      dispatch(QuotationSlice.actions.setCurrentChannel(quoteValues.channel));

      if (
        quoteValues.amount !== quoterValue.current?.amount ||
        quoteValues.currency !== quoterValue.current?.currency ||
        quoteValues.country !== quoterValue.current?.country ||
        quoteValues.deliveryMethod !== quoterValue.current?.deliveryMethod ||
        quoteValues.sendTo !== quoterValue.current?.sendTo ||
        quoteValues.channel !== quoterValue.current.channel
      ) {
        setQuoteValues(quoteValues, deliveryMethodDestinations);
      } else if (
        quoteValues.paymentMethodSource &&
        quoterValue.current.currentQuote?.paymentMethods &&
        quoteValues.paymentMethodSource !== quoterValue.current.paymentMethodSource
      ) {
        quoterValue.current = {
          ...quoterValue.current,
          ...quoteValues,
        };
        let coupon = couponToApply;
        if (!coupon && !isEmpty(couponsGrouped)) {
          dispatch(QuotationSlice.actions.setCouponToApply(couponsGrouped[0]));
          coupon = couponsGrouped[0];
          if (coupon) dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(undefined));
        }

        const paymentMethod = structuredClone(
          quoterValue.current.currentQuote?.paymentMethods?.find(
            (pm) => pm.paymentOrigin === quoteValues.paymentMethodSource
          )
        );

        const sendingFee = quoterValue.current.currentQuote?.fee;
        if (coupon && paymentMethod && quoteValues.country && sendingFee) {
          await verifyCouponToApply(
            {
              amount: quoteValues.amount,
              corridor: deliveryMethodDestinations.find((dest) => dest.id === quoteValues.sendTo)?.destination || "",
              couponCode: coupon.code,
              deliveryMethod: quoteValues.deliveryMethod,
              paymentMethod: quoteValues.paymentMethodSource,
              paymentMethodFee: paymentMethod.paymentMethodFee,
              fee: sendingFee,
              countryCode: quoteValues.country,
              countrySubdivision: personVeriff?.countrySubdivision || "",
            },
            coupon
          );
        }
      }
    }
  };

  const setQuoteValues = async (
    quoteValues: QuoteSelectedValue,
    destinations?: DestinationByCountry["destinations"]
  ) => {
    quoterValue.current = {
      ...quoterValue.current,
      ...quoteValues,
    };
    if (quoteValues.currency && quoteValues.amount && quoteValues.deliveryMethod && quoteValues.sendTo) {
      await getCurrentQuote(
        quoteValues.currency,
        quoteValues.amount,
        quoteValues.sendTo,
        quoteValues.deliveryMethod,
        quoteValues.withTiers,
        quoteValues.country,
        quoteValues.paymentMethodSource,
        quoteValues.channel,
        destinations
      );
    } else if (!quoteValues.sendTo) {
      clearPaymentDestination();
    }
  };

  const getCurrentQuote = async (
    currency: string,
    amount: number,
    sendTo: string,
    deliveryMethod: QuoteDeliveryMethodCode,
    withTiers?: boolean,
    country?: string | null,
    paymentMethodSource?: PaymentMethods,
    channel?: QuoteChannel | null,
    destinations?: DestinationByCountry["destinations"]
  ) => {
    startRequest();

    try {
      const countryDestinationObj = country ? getCountryDestination(country, countries) : null;
      const countryDestination = country || getQuoteCountryDestinationCode(currency, countries);
      if (!countryDestination) {
        endRequest();
        return;
      }
      const deliveryMethodDestinations =
        destinations ||
        (await getDeliveryMethodDestinations({
          deliveryMethod,
          countryDestination,
          sponsorBank: (paymentMethodSelected as PaymentMethod)?.sponsorBank,
        }));
      const selectedDestination = await setPaymentDestination(
        sendTo,
        deliveryMethod,
        countryDestination,
        deliveryMethodDestinations
      );
      const amountIsValid = checkDestinationAmountRange(amount, selectedDestination);

      if (amountIsValid) {
        const useCase =
          withTiers && (personVeriff || personVerifyKYC) && !channel ? getQuoteWithFeesAndTiers : getQuoteWithFees;

        const response = await useCase(createApiQuoteRepository())(
          countryDestination,
          countryDestinationObj?.currency || currency,
          amount,
          selectedDestination?.payerQuote,
          channel
        );

        const quoteDeliveryMethod = getQuoteDeliveryMethod(response.deliveryMethods, deliveryMethod);
        dispatch(QuotationSlice.actions.setCurrentDeliveryMethodQuote(quoteDeliveryMethod));

        quoterValue.current = {
          ...quoterValue.current,
          currentQuote: quoteDeliveryMethod,
        };

        if (response?.destinationCurrency) {
          response.destinationCurrency = currency;
        }

        dispatch(
          QuotationSlice.actions.setCurrentQuotation({
            currentQuotation: response,
            countryDestination,
          })
        );

        if (channel) {
          const nextChannel = Object.values(QuoteChannel).filter((c) => c !== channel)[0];
          const responseNextChannel = await useCase(createApiQuoteRepository())(
            countryDestination,
            countryDestinationObj?.currency || currency,
            amount,
            selectedDestination?.payerQuote,
            nextChannel
          );

          const quoteDeliveryMethodNextChannel = getQuoteDeliveryMethod(
            responseNextChannel.deliveryMethods,
            deliveryMethod
          );
          dispatch(
            QuotationSlice.actions.setQuotesPerChannel({
              [channel]: {
                currentQuotation: response,
                countryDestination,
                currentDeliveryMethodQuote: quoteDeliveryMethod,
                currentPaymentMethod: quoteDeliveryMethod?.paymentMethods?.find(
                  (pm) => pm.paymentOrigin === currentPaymentMethod?.paymentOrigin
                ),
                currentPaymentDestination: selectedDestination,
              },
              [nextChannel]: {
                currentQuotation: responseNextChannel,
                countryDestination,
                currentDeliveryMethodQuote: quoteDeliveryMethodNextChannel,
                currentPaymentMethod: quoteDeliveryMethodNextChannel?.paymentMethods?.find(
                  (pm) => pm.paymentOrigin === currentPaymentMethod?.paymentOrigin
                ),
                currentPaymentDestination: selectedDestination,
              },
            })
          );
        }

        let coupon = couponToApply;
        if (!coupon && !isEmpty(couponsGrouped)) {
          dispatch(QuotationSlice.actions.setCouponToApply(couponsGrouped[0]));
          coupon = couponsGrouped[0];
          if (coupon) dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(undefined));
        }

        const paymentMethod = structuredClone(
          quoteDeliveryMethod?.paymentMethods?.find((pm) => pm.paymentOrigin === paymentMethodSource)
        );

        if (coupon && quoteDeliveryMethod && paymentMethod && personVeriff) {
          await verifyCouponToApply(
            {
              amount,
              corridor:
                quoteDeliveryMethod.paymentDestinations.find((dest) => dest.id === selectedDestination?.id)
                  ?.destination || "",
              couponCode: coupon.code,
              deliveryMethod,
              paymentMethod: paymentMethod.paymentOrigin,
              paymentMethodFee: paymentMethod.paymentMethodFee,
              fee: quoteDeliveryMethod.fee,
              countryCode: countryDestination,
              countrySubdivision: personVeriff.countrySubdivision || "",
            },
            coupon
          );
        }
        endRequest();
        return response;
      } else {
        dispatch(
          QuotationSlice.actions.setCurrentPaymentDestination(
            getQuoteMethodDestination(deliveryMethodDestinations, sendTo)
          )
        );
      }
      endRequest();
    } catch (error) {
      endRequest({ hasError: true });
      setError(error as Errors);
    }
  };

  const getDeliveryMethodDestinations = async ({
    countryDestination,
    deliveryMethod,
    sponsorBank = "NoSponsor",
  }: {
    countryDestination?: string;
    deliveryMethod: QuoteDeliveryMethodCode;
    sponsorBank?: string;
  }) => {
    let deliveryMethodDestinations: (SelectOption & Destination)[] | (SelectOption & QuotePaymentMethodDestination)[] =
      [];
    if (countryDestination) {
      const destinationFound = destinationsByCountryRef.current.find(
        (dest) =>
          dest.country === countryDestination && dest.method === deliveryMethod && dest.sponsorBank === sponsorBank
      );
      if (!destinationFound) {
        deliveryMethodDestinations =
          (await getDestinationsByCountry(deliveryMethod, countryDestination, sponsorBank)) || [];
        destinationsByCountryRef.current = [
          ...destinationsByCountryRef.current,
          {
            country: countryDestination,
            destinations: deliveryMethodDestinations,
            method: deliveryMethod,
            sponsorBank: sponsorBank || "NoSponsor",
          },
        ];
      } else {
        deliveryMethodDestinations = destinationFound.destinations;
      }
    }

    return deliveryMethodDestinations;
  };

  async function setPaymentDestination(
    sendTo?: string | null | undefined,
    deliveryMethod?: QuoteDeliveryMethod["deliveryMethodCode"],
    countryDestination?: string,
    destinations?: DestinationByCountry["destinations"]
  ) {
    if (sendTo && deliveryMethod && countryDestination) {
      const deliveryMethodDestinations =
        destinations ||
        (await getDeliveryMethodDestinations({
          deliveryMethod,
          countryDestination,
          sponsorBank: (paymentMethodSelected as PaymentMethod)?.sponsorBank,
        }));
      const selectedDestination = getQuoteMethodDestination(deliveryMethodDestinations!, sendTo);
      dispatch(QuotationSlice.actions.setCurrentPaymentDestination(selectedDestination));

      quoterValue.current = {
        ...quoterValue.current,
        sendTo,
      };
      return selectedDestination;
    } else {
      clearPaymentDestination();
      return;
    }
  }

  function clearPaymentDestination() {
    quoterValue.current = {
      ...quoterValue.current,
      currentQuote: undefined,
    };
    dispatch(QuotationSlice.actions.setCurrentPaymentDestination(null));
    dispatch(QuotationSlice.actions.setCurrentPaymentMethod(null));
  }

  async function fetchPaymentMethods() {
    startRequest();
    try {
      const response = await getPaymentMethods(createApiPaymentMethodRepository())();

      endRequest();
      dispatch(QuotationSlice.actions.setPaymentMethods(response));
      return response;
    } catch (error) {
      endRequest();
      dispatch(QuotationSlice.actions.setPaymentMethods([]));
    }
  }

  async function fetchPlaidAccountDetails(accountId: string, language: string) {
    startRequest();
    try {
      const response = await getAccountDetails(PlaidPaymentOrderRepository())(accountId, language);

      if (paymentMethodSelected?.paymentSource === "Sendola Plaid") {
        dispatch(
          QuotationSlice.actions.setPaymentMethodSelected({
            ...paymentMethodSelected,
            accountNumber: response.account,
          })
        );
      }

      endRequest();
      return response;
    } catch (error) {
      endRequest({ hasError: true });
      setError(error as Errors);
    }
  }

  async function verifyCouponToApply(verifyPayload: VerifyCouponPayloadShort, couponToApply: CouponGroup) {
    const couponVerification = await verifyQuoteCoupon(verifyPayload);

    if (couponVerification?.quoteDiscounted) {
      dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(couponVerification.quoteDiscounted));
    } else {
      const nextCoupon = getNextCoupon(couponToApply, couponsGrouped);
      dispatch(QuotationSlice.actions.setCouponToApply(nextCoupon));

      if (nextCoupon) {
        await verifyCouponToApply({ ...verifyPayload, couponCode: nextCoupon.code }, nextCoupon);
      } else {
        dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(undefined));
      }
    }
  }

  useEffect(() => {
    if (hasError && error?.errors?.some((err) => (err as string)?.includes("Tier"))) {
      showQuoteModal({
        modalType: "error",
        errorMessage: tModals("quote.errorMessageTier"),
      });
    } else if (hasError) {
      showQuoteModal({
        modalType: "error",
        errorMessage: tModals("quote.errorMessage"),
      });
    }
  }, [hasError]);

  useEffect(() => {
    const paymentMethod = paymentMethodSelected as PlaidAccount;
    if (paymentMethodSelected?.paymentSource === "Sendola Plaid" && !paymentMethod.accountNumber && paymentMethod.id) {
      fetchPlaidAccountDetails(paymentMethod.id, language);
    }

    if (paymentMethodSelected) {
      (async () => {
        const deliveryMethodDestinations = await getDeliveryMethodDestinations({
          countryDestination: countryDestination,
          deliveryMethod: quoterValue.current.deliveryMethod || "D2B",
          sponsorBank: (paymentMethodSelected as PaymentMethod)?.sponsorBank || "NoSponsor",
        });
        setSendToSelectOptions(deliveryMethodDestinations);
      })();
    }
  }, [paymentMethodSelected]);

  useEffect(() => {
    if (currentDeliveryMethodQuote?.paymentMethods && paymentMethodSelected) {
      const methodFromQuote = structuredClone(
        currentDeliveryMethodQuote.paymentMethods.find((method) =>
          paymentMethodSelected.paymentSource === "Sendola Plaid"
            ? method.paymentOrigin === "Sendola ACH"
            : paymentMethodSelected.paymentSource === "Coppel"
            ? method.paymentOrigin === "Sendola Card"
            : method.paymentOrigin === paymentMethodSelected.paymentSource
        )
      );

      if (
        methodFromQuote &&
        (methodFromQuote.paymentOrigin !== currentPaymentMethod?.paymentOrigin ||
          methodFromQuote.totalCost !== currentPaymentMethod?.totalCost)
      ) {
        dispatch(QuotationSlice.actions.setCurrentPaymentMethod(methodFromQuote));
      } else if (!methodFromQuote) {
        dispatch(QuotationSlice.actions.setCurrentPaymentMethod(undefined));
      }
    }
  }, [paymentMethodSelected, currentDeliveryMethodQuote]);

  useEffect(() => {
    dispatch(paymentOrderSlice.actions.resetState());
    const urlSearchParams = new URLSearchParams(location.search);

    if (location.state?.previous !== "createRecipient" && !urlSearchParams.get("preserveQuote")) {
      dispatch(QuotationSlice.actions.resetState());
    } else {
      dispatch(QuotationSlice.actions.setCouponToApply(undefined));
      dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(undefined));
      dispatch(QuotationSlice.actions.setQuotesPerChannel(undefined));
    }
  }, []);

  return {
    isLoading,
    hasError,
    quoterValue,
    sendToSelectOptions,
    deliveryMethodsSelectOptions,
    countryDestination,
    currentPaymentDestination,
    quoteModal,
    paymentMethods,
    getCurrentQuote,
    handleQuotationChange,
    resetQuote,
    fetchPaymentMethods,
  };
};
