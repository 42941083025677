import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { format } from "date-fns";
import { isNil } from "lodash";

// own states
import { State } from "../../../Redux/Store";
import { TransferOrdersSelector } from "../../../Redux/TransferOrders/TransferOrders.slice";

// Own components
import Layout from "../../../Components/Layout";
import {
  Header,
  LinkText,
  OptionsSection,
  DownloadReceipt,
  Amount,
  IconCountry,
  SwitchContainer,
  SwitchBtn,
  ContainerMTCN,
  LabelCard,
  ActionsIcons,
  DetailCard,
  TextCopy,
  MessageContainer,
  Message,
  CopyCOntainer,
  ButtonDetail,
  MessageContainerTransaction,
  SwitchStyled,
  MessageContainerError,
  StatusText,
  IconStatus,
  TableInfoStyled,
  TableRow,
  TableColumn,
  Divider,
  TextContainer,
  HintTooltip,
} from "./TransactionHistoryDetailsPage.styled";
import Title from "../../../Components/Title";
import Icon from "../../../Components/Icon";
import TableInfo from "../../../Components/TableInfo";
import IconShape from "../../../Components/IconShape";

// Own models
import { RowBold } from "../../../Models/TableInfo";
import { PaymentOrderStatus } from "../../../Modules/PaymentOrders/domain/PaymentOrder";

import getDateFnsLocale from "../../../Utilities/dateFnsLocale";

import IconFont from "../../../Components/IconFont";
import { FlexContainer, FlexItem } from "../../../Components/Flex/Flex.styled";
import { formatNumber } from "../../../Utilities/NumberUtils";
import Text from "../../../Components/Text";
import { Fragment, useEffect, useState } from "react";
import { CopyTextToClipboard } from "../../../Utilities/CopyTextToClipboard";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
import ProgressStepSCustom from "../../../Components/StepsCustom";
import { usePaymentOrder } from "../../../Hooks/usePaymentOrders";
import { PaymentOrderTracking } from "../../../Modules/PaymentOrders/domain/PaymentOrderCreated";
import { countrySelector } from "../../../Redux/Country/Country.slice";
import { ExchangeRate } from "../../../Modules/Common/domain/ExchangeRates";

import ClockIcon from "../../../Assets/Icons/clock.svg";
import CircleCheck from "../../../Assets/Icons/circleCheckDark.svg";
import CircleX from "../../../Assets/Icons/cancelDark.svg";
import { maskInterbankKey } from "../../../Utilities/String";
import { useModal } from "../../../Hooks/useModal";

export function getLocalStorageObject(key: string) {
  const item = localStorage.getItem(key) || "";
  const parsedItem = item ? JSON.parse(item) : {};

  return parsedItem;
}

const TransactionHistoryDetailsPage = () => {
  const theme = useSelector((state: State) => state.theme);
  const { t, i18n } = useTranslation("global");
  const [details, setDetails] = useState(true);
  const [copiedText, setCopiedText] = useState<boolean>(false);
  const navigate = useNavigate();
  const transferOrderDetails = useSelector(TransferOrdersSelector).data
    ?.transactionDetails;
  const trackingTransferOrder = useSelector(TransferOrdersSelector).data
    ?.transactionTracking;
  const transferOrderDetailsFromCard = useSelector(TransferOrdersSelector).data
    ?.transactionCard;
  const { getTransactionTracking } = usePaymentOrder();
  const [completeTransferDate, setCompleteTransferDate] = useState("");
  const [transferDateWithTime, setTransferDateWithTime] = useState("");
  const countries = useSelector(countrySelector).countries as ExchangeRate[];
  const [showHintTooltip, setShowHintTooltip] = useState(false);
  const { modal: modalZelle, showModal: showModalZelle } = useModal();

  if (isNil(transferOrderDetails)) {
    return (window.location.href = "/dashboard");
  }

  const isZelleTransaction =
    transferOrderDetails?.payment?.productType.toLowerCase() === "zelle";

  const isVenmoTransaction =
    transferOrderDetails?.payment?.productType.toLowerCase() === "venmo";

  const TransferDetails = [
    {
      id: "date",
      label: t("Transactions.dateAvailable"),
      value: `${format(
        new Date(transferOrderDetails.instruction.quote.quoteUpdatedAt),
        "MMM dd, yyyy",
        {
          locale: getDateFnsLocale(i18n.language),
        }
      )},  ${new Date(
        transferOrderDetails.instruction.quote.quoteUpdatedAt
      ).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`,
      bold: RowBold.LEFT,
      padding: "12px 0 0 0",
    },
    {
      id: "delivery",
      label: t("Transactions.Delivery"),
      value: transferOrderDetails.payment.productType,
      bold: RowBold.LEFT,
      padding: "12px 0 0 0",
    },
    {
      id: "bankName",
      label: `${
        transferOrderDetails.instruction?.quote?.type === "D2B"
          ? t("ReviewTransaction.PayOutMethod.DirectToBank")
          : t("ReviewTransaction.PayOutMethod.CashPickUp")
      }`,
      value: `${
        transferOrderDetails.instruction?.destination?.destinationName
      } ${maskInterbankKey(
        transferOrderDetails.instruction?.destination?.accountNumber
      )}`,
      bold: RowBold.LEFT,
      padding: "12px 0 0 0",
      withDivider: true,
    },
    {
      id: "exchangeRate",
      label: `${t("Transactions.exchangeRate")}`,
      value: `1 USD = ${transferOrderDetails.instruction?.quote?.fx} ${transferOrderDetails.instruction?.destination?.currency}`,
      bold: RowBold.LEFT,
      padding: "0",
      withDivider: false,
    },
    {
      id: "TransactionFees",
      label: `${t("Transactions.TransactionFees")}`,
      value: `${transferOrderDetails.instruction?.quote?.fee} USD`,
      bold: RowBold.LEFT,
      padding: "12px 0 0 0",
      withDivider: true,
    },
    {
      id: "TotalPayment",
      label: `${t("Transactions.TotalPayment")}`,
      value: `${transferOrderDetails.payment?.amount} USD`,
      bold: RowBold.ROW,
      padding: "0",
      withDivider: false,
    },
  ];

  const viewType = (type: string) => {
    if (type === "details") {
      setDetails(true);
    } else {
      setDetails(false);
    }
  };

  const copyTextMtcn = () => {
    setCopiedText(true);
    CopyTextToClipboard(transferOrderDetails.mtcn);
    setTimeout(() => {
      setCopiedText(false);
    }, 1000);
  };

  useEffect(() => {
    getTracking();
  }, []);

  const getTracking = async () => {
    if (transferOrderDetails.id) {
      await getTransactionTracking(transferOrderDetails.id);
    }
  };

  useEffect(() => {
    if (trackingTransferOrder) {
      const tracking = trackingTransferOrder.find(
        (item: PaymentOrderTracking) => item.statusCode === "paid"
      );
      if (tracking) {
        const trackingDate = `${format(
          new Date(tracking.date),
          "MMM dd, yyyy",
          {
            locale: getDateFnsLocale(i18n.language),
          }
        )}`;
        setTransferDateWithTime(tracking.date);
        setCompleteTransferDate(trackingDate);
      } else {
        setCompleteTransferDate("");
      }
    }
  }, [TransferDetails, trackingTransferOrder]);

  const transferTracking = [
    {
      label: t("Transactions.CreateTransferTitle"),
      description: t("Transactions.CreateTransfer"),
      date: `${format(
        new Date(transferOrderDetails.orderedAt),
        "MMM dd, yyyy",
        { locale: getDateFnsLocale(i18n.language) }
      )}`,
      step: 1,
      activeIcon: false,
    },
    {
      label: t("Transactions.TransferCompletedTitle"),
      description: t("Transactions.TransferCompleted"),
      date: completeTransferDate,
      step: 2,
      activeIcon: true,
    },
  ];

  const ProcessedDate = [
    {
      id: "ProcessedDate",
      label: `${t("Transactions.ProcessedDate")}`,
      value: transferDateWithTime
        ? `${format(new Date(transferDateWithTime), "MMM dd, yyyy", {
            locale: getDateFnsLocale(i18n.language),
          })},  ${new Date(transferDateWithTime).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`
        : "",
      bold: RowBold.ROW,
      padding: "12px 0 0 0",
      withDivider: false,
    },
  ];

  const openZelleInstructions = () => {
    if (transferOrderDetails) {
      showModalZelle({
        modalType: "zelleInstructionsModal",
        finish: true,
        reference: transferOrderDetails.mtcn,
        amount: transferOrderDetails.payment.amount
          ? Number(transferOrderDetails.payment.amount)
          : Number(transferOrderDetails.instruction.origination.amount) +
            Number(transferOrderDetails.instruction.quote.fee) +
            Number(transferOrderDetails.instruction.quote.paymentMethodFee),
      });
    }
  };


  const openVenmoInstructions = () => {
    if (transferOrderDetails) {
      showModalZelle({
        modalType: "venmoInstructionsModal",
        finish: true,
        reference: transferOrderDetails.mtcn,
        amount: transferOrderDetails.payment.amount
          ? Number(transferOrderDetails.payment.amount)
          : Number(transferOrderDetails.instruction.origination.amount) +
            Number(transferOrderDetails.instruction.quote.fee) +
            Number(transferOrderDetails.instruction.quote.paymentMethodFee),
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout
        path="/dashboard"
        pathState={{ activeTab: "transactions" }}
        textLink={t("buttons.back")}
      >
        <FlexContainer
          direction="column"
          justify="center"
          alignItems="center"
          p="12px 16px"
        >
          <FlexItem
            mw="532px"
            justify="center"
            alignItems="center"
            direction="column"
          >
            <MessageContainer>
              <Title
                tagName="h1"
                text={t("Transactions.TitleTransactions")}
                size={3}
                align="left"
                color="black"
              />
            </MessageContainer>
          </FlexItem>
          <FlexItem mw="532px" justify="center" direction="column">
            <Header>
              <Title
                tagName="h1"
                text={`$ ${formatNumber(
                  transferOrderDetails.instruction?.origination?.amount || 0,
                  {
                    minimumFractionDigits: 2,
                  }
                )}`}
                size={5}
                align="center"
                color="black"
              />
              <Amount>
                {formatNumber(
                  transferOrderDetails.instruction?.destination?.amount || 0,
                  {
                    minimumFractionDigits: 2,
                  }
                )}{" "}
                {transferOrderDetails.instruction?.destination?.currency}
              </Amount>
              <IconCountry>
                <img
                  src={
                    countries.find(
                      (exchange) =>
                        exchange.countryCode ===
                        transferOrderDetails.instruction?.destination?.country
                    )?.iconUs
                  }
                />
              </IconCountry>
              <Text
                size={"default"}
                align="center"
                color="grey"
                weight={500}
                margin="0 0 4px 0"
              >
                {t("Transactions.Sending")}
              </Text>
              <Text
                size={0.5}
                align="center"
                color="black"
                weight={600}
                margin="0 0 24px 0"
              >
                {transferOrderDetails.receiver?.firstName}{" "}
                {transferOrderDetails.receiver?.middleName}{" "}
                {transferOrderDetails.receiver?.lastName}
              </Text>
              {transferOrderDetailsFromCard && (
                <StatusText status={transferOrderDetailsFromCard?.status}>
                  {transferOrderDetailsFromCard?.status.toLowerCase() ===
                    PaymentOrderStatus.pending ||
                  transferOrderDetailsFromCard?.status.toLowerCase() ===
                    PaymentOrderStatus.funds ||
                  transferOrderDetailsFromCard?.status.toLowerCase() ===
                    PaymentOrderStatus.verified ||
                  transferOrderDetailsFromCard?.status.toLowerCase() ===
                    PaymentOrderStatus.accepted ||
                  transferOrderDetailsFromCard?.status.toLowerCase() ===
                    PaymentOrderStatus.review ||
                  transferOrderDetailsFromCard?.status.toLowerCase() ===
                    PaymentOrderStatus.available ? (
                    <IconStatus src={ClockIcon} />
                  ) : transferOrderDetailsFromCard?.status.toLowerCase() ===
                      PaymentOrderStatus.rejected ||
                    transferOrderDetailsFromCard?.status.toLowerCase() ===
                      PaymentOrderStatus.canceled ? (
                    <IconStatus src={CircleX} />
                  ) : transferOrderDetailsFromCard?.status.toLowerCase() ===
                      PaymentOrderStatus.paid ||
                    transferOrderDetailsFromCard?.status.toLowerCase() ===
                      PaymentOrderStatus.declined ? (
                    <IconStatus src={CircleCheck} />
                  ) : (
                    ""
                  )}

                  <span>
                    {t(
                      transferOrderDetailsFromCard?.status?.toLowerCase() ===
                        PaymentOrderStatus.settled
                        ? "TransactionStatuses.Paid"
                        : transferOrderDetailsFromCard?.status?.toLowerCase() ===
                          PaymentOrderStatus.paid
                        ? "TransactionStatuses.Paid"
                        : transferOrderDetailsFromCard?.status?.toLowerCase() ===
                          PaymentOrderStatus.canceled
                        ? "TransactionStatuses.Cancelled"
                        : transferOrderDetailsFromCard?.status?.toLowerCase() ===
                          PaymentOrderStatus.pending
                        ? "TransactionStatuses.Pending"
                        : transferOrderDetailsFromCard?.status?.toLowerCase() ===
                          PaymentOrderStatus.rejected
                        ? "TransactionStatuses.Rejected"
                        : transferOrderDetailsFromCard?.status?.toLowerCase() ===
                          PaymentOrderStatus.available
                        ? "TransactionStatuses.Available"
                        : transferOrderDetailsFromCard?.status?.toLowerCase() ===
                          PaymentOrderStatus.funds
                        ? "TransactionStatuses.Funds"
                        : transferOrderDetailsFromCard?.status?.toLowerCase() ===
                          PaymentOrderStatus.inReview
                        ? "TransactionStatuses.InReview"
                        : transferOrderDetailsFromCard?.status?.toLocaleLowerCase() ===
                          PaymentOrderStatus.acceptedByRail
                        ? "TransactionStatuses.AcceptedByRail"
                        : transferOrderDetailsFromCard?.status?.toLocaleLowerCase() ===
                          PaymentOrderStatus.verified
                        ? "TransactionStatuses.Verified"
                        : transferOrderDetailsFromCard?.status?.toLocaleLowerCase() ===
                          PaymentOrderStatus.created
                        ? "TransactionStatuses.Created"
                        : transferOrderDetailsFromCard?.status?.toLocaleLowerCase() ===
                          PaymentOrderStatus.declined
                        ? "TransactionStatuses.Declined"
                        : transferOrderDetailsFromCard?.status || ""
                    )}
                  </span>
                </StatusText>
              )}
            </Header>
          </FlexItem>
          <FlexItem
            mw="532px"
            justify="center"
            direction="column"
            alignItems="center"
            w="100%"
          >
            <SwitchStyled>
              <SwitchContainer>
                <SwitchBtn
                  $active={details}
                  onClick={() => viewType("details")}
                >
                  <span>{t("Transactions.TransactionDetails")}</span>
                </SwitchBtn>
                <SwitchBtn $active={!details} onClick={() => viewType("track")}>
                  <span>
                    {t("Transactions.TrackStatus")}
                    <Icon
                      icon="send"
                      color={!details ? "solid_light" : "disabled"}
                      size="small"
                    />
                  </span>
                </SwitchBtn>
              </SwitchContainer>
            </SwitchStyled>
            {details ? (
              <>
                <ContainerMTCN>
                  <Text
                    size={0.5}
                    align="center"
                    color="black"
                    weight={500}
                    margin="16px 0 16px 16px"
                  >
                    MTCN
                  </Text>

                  <LabelCard>
                    {transferOrderDetails.lastStatus.toLowerCase() ===
                      PaymentOrderStatus.funds && (isZelleTransaction || isVenmoTransaction) ? (
                      <Text
                        size={0.5}
                        letterSpacing="8.5px"
                        color="black"
                        margin="0 8px 0 0"
                      >
                        {t("TransactionCompleted.PendingMTCN")}
                      </Text>
                    ) : (
                      <>
                        <Text size={0.5} color="black" margin="0 8px 0 0">
                          {transferOrderDetails.mtcn}
                        </Text>
                        <ActionsIcons onClick={() => copyTextMtcn()}>
                          <IconFont name="copy" size="small" color="primary" />
                          <Text
                            align="center"
                            color="solid_light"
                            weight={600}
                            margin="16px 0"
                          >
                            {t("Transactions.Copy")}
                          </Text>
                        </ActionsIcons>
                      </>
                    )}
                  </LabelCard>
                  {copiedText && (
                    <CopyCOntainer>
                      <TextCopy>
                        <IconShape
                          icon="circle-check"
                          iconSize="small"
                          border="rounded"
                          bgColor="black"
                          height={16}
                          width={16}
                        />
                        <span>{t("Transactions.Copied")}</span>
                      </TextCopy>
                    </CopyCOntainer>
                  )}
                </ContainerMTCN>
                <FlexContainer p="16px">
                  {isZelleTransaction && (
                    <Button
                      variant="transparent"
                      padding="0px"
                      sizeButton="full"
                      color="zelle"
                      onClick={openZelleInstructions}
                    >
                      <FlexContainer gap="11px" justify="center">
                        <IconFont name="info" color="zelle" size="medium" />
                        <Text size={1} weight={600} color="zelle" align="left">
                          {t("Zelle.instructions.cta")}
                        </Text>
                      </FlexContainer>
                    </Button>
                  )}
                  {isVenmoTransaction && (
                    <Button
                      variant="transparent"
                      padding="0px"
                      sizeButton="full"
                      color="venmo"
                      onClick={openVenmoInstructions}
                    >
                      <FlexContainer gap="11px" justify="center">
                        <IconFont name="info" color="venmo" size="medium" />
                        <Text size={1} weight={600} color="venmo" align="left">
                          {t("Venmo.instructions.cta")}
                        </Text>
                      </FlexContainer>
                    </Button>
                  )}
                </FlexContainer>
                <DetailCard>
                  <TableInfoStyled>
                    {TransferDetails.map((row, index) => (
                      <Fragment key={index}>
                        <TableRow>
                          <TableColumn
                            fontWeight={row.id === "TotalPayment" ? 700 : 500}
                            padding={row?.padding || "24 0"}
                            grow={1.2}
                            color={"grey"}
                            textAlign="left"
                          >
                            {row.id == "TransactionFees" && showHintTooltip && (
                              <HintTooltip
                                onMouseEnter={() => setShowHintTooltip(true)}
                                onMouseLeave={() => setShowHintTooltip(false)}
                                dangerouslySetInnerHTML={{
                                  __html: t("Transactions.MessageFees"),
                                }}
                              ></HintTooltip>
                            )}
                            <TextContainer>
                              {row.label}
                              {row.id == "TransactionFees" && (
                                <div
                                  onMouseEnter={() => setShowHintTooltip(true)}
                                  onMouseLeave={() => setShowHintTooltip(false)}
                                >
                                  <IconShape
                                    icon="info"
                                    iconSize="medium"
                                    iconColor="primary"
                                    bgColor="transparent"
                                    height={16}
                                    width={16}
                                  />
                                </div>
                              )}
                            </TextContainer>
                          </TableColumn>

                          <TableColumn
                            textAlign="right"
                            fontWeight={row.id === "TotalPayment" ? 700 : 500}
                            color={"black_highlight"}
                            padding={row?.padding || "24px 0"}
                          >
                            {row.value}
                          </TableColumn>
                        </TableRow>

                        {row.withDivider && <Divider />}
                      </Fragment>
                    ))}
                    {transferOrderDetailsFromCard?.status.toLowerCase() ===
                      PaymentOrderStatus.paid &&
                      ProcessedDate.map((row, index) => (
                        <Fragment key={index}>
                          <TableRow>
                            <TableColumn
                              fontWeight={500}
                              padding={row?.padding || "24 0"}
                              grow={1.2}
                              color={"grey"}
                              textAlign="left"
                            >
                              <TextContainer>{row.label}</TextContainer>
                            </TableColumn>

                            <TableColumn
                              textAlign="right"
                              fontWeight={500}
                              color={"black_highlight"}
                              padding={row?.padding || "24px 0"}
                            >
                              {row.value}
                            </TableColumn>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableInfoStyled>
                  <OptionsSection>
                    <DownloadReceipt
                      href={transferOrderDetails.receipt}
                      download={`${transferOrderDetails.mtcn}`}
                      target="_blank"
                    >
                      <LinkText color="primary">
                        {transferOrderDetails.lastStatus.toLowerCase() ===
                        PaymentOrderStatus.paid
                          ? t("Transactions.Downloadreceipt")
                          : t("Transactions.DownloadPrereceipt")}
                      </LinkText>
                      <IconFont name="download" color="primary" />
                    </DownloadReceipt>
                  </OptionsSection>
                </DetailCard>
              </>
            ) : (
              <DetailCard>
                <Text
                  size={0.5}
                  align="left"
                  color="black"
                  weight={600}
                  margin="16px 0 0 12px"
                >
                  {t("Transactions.TransactionTracking")}
                </Text>
                {transferOrderDetails.lastStatus.toLowerCase() ===
                PaymentOrderStatus.rejected ? (
                  <MessageContainerError>
                    <Icon icon="circleWarning" color="error" size="medium" />
                    <Message>
                      <Title
                        color={"error"}
                        text={t("Transactions.TransferError")}
                        size={0.5}
                        tagName="h1"
                        align="left"
                      />
                      <Text
                        align="left"
                        color={"error"}
                        size={"default"}
                        weight={400}
                      >
                        {t("Transactions.TextError")}
                      </Text>
                    </Message>
                  </MessageContainerError>
                ) : (
                  <ProgressStepSCustom
                    enabledStatus={
                      transferOrderDetails.lastStatus.toLowerCase() ===
                      PaymentOrderStatus.paid
                    }
                    steps={transferTracking}
                  ></ProgressStepSCustom>
                )}
              </DetailCard>
            )}
          </FlexItem>
          {details && (
            <FlexItem
              justify="center"
              direction="column"
              alignItems="center"
              mw="532px"
            >
              <MessageContainerTransaction>
                {transferOrderDetails.lastStatus.toLowerCase() ===
                  PaymentOrderStatus.rejected && (
                  <Icon icon="circleWarning" color="error" size="medium" />
                )}
                <Message>
                  <Title
                    color={
                      transferOrderDetails.lastStatus.toLowerCase() ===
                      PaymentOrderStatus.rejected
                        ? "error"
                        : "solid_light"
                    }
                    text={
                      transferOrderDetails.lastStatus.toLowerCase() ===
                      PaymentOrderStatus.rejected
                        ? t("Transactions.TransferError")
                        : t("Transactions.ProblemTitle")
                    }
                    size={0.5}
                    tagName="h1"
                    align="left"
                  />
                  <Text
                    align="left"
                    color={
                      transferOrderDetails.lastStatus.toLowerCase() ===
                      PaymentOrderStatus.rejected
                        ? "error"
                        : "grey"
                    }
                    size={"default"}
                    weight={400}
                  >
                    {transferOrderDetails.lastStatus.toLowerCase() ===
                    PaymentOrderStatus.rejected
                      ? t("Transactions.TextError")
                      : t("Transactions.ProblemText")}
                  </Text>
                </Message>
              </MessageContainerTransaction>
            </FlexItem>
          )}
          <FlexItem
            justify="center"
            direction="column"
            alignItems="center"
            mw="260px"
            w="100%"
          >
            <Button
              text={t("global.contactSupport")}
              onClick={() => {
                navigate("/contact-us");
              }}
              sizeText="medium"
              variant="outline"
              as={ButtonDetail}
            />
          </FlexItem>
        </FlexContainer>
        {modalZelle}
      </Layout>
    </ThemeProvider>
  );
};

export default TransactionHistoryDetailsPage;
