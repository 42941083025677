import { useTranslation } from "react-i18next";
import Text from "../../../../Components/Text";
import { Controller, useFormContext } from "react-hook-form";
import { SearchSelect } from "../../../../Components/SearchSelect/SearchSelect.component";
import { useCountry } from "../../../../Hooks/useCountry";
import { useSubdivision } from "../../../../Hooks/useSubdivision";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../../Redux/Translate";
import { useEffect, useMemo, useRef } from "react";
import { SelectInstance } from "react-select";
import { SelectOption } from "../../../../Models/Select";
import { useCity } from "../../../../Hooks/useCity";
import { FormInput } from "../../../../Components/Input";
import { CountryCodeList } from "../../../../Modules/Common/domain/PhoneCountryCode";

interface AddressFormProps {
  countrySelected?: string | null;
  subdivisionSelected?: string | null;
  citySelected?: string | null;
  onLoadSubdivisions?: (subdivisions: SelectOption[]) => void;
  onSelectCountry?: (countryCode: string) => void;
}

export const AddressForm = ({
  countrySelected,
  subdivisionSelected,
  citySelected,
  onLoadSubdivisions,
  onSelectCountry,
}: AddressFormProps) => {
  const { t } = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const { countriesWithoutUSAOptions, isLoading: countryIsLoading } = useCountry();
  const { subdivisionList, isLoading: subdivisionIsLoading, getSubdivisionOptions } = useSubdivision();
  const { citiesList, isLoading: cityIsLoading, getSubdivisionCitiesOptions } = useCity();
  const subdivRef = useRef<SelectInstance<SelectOption> | null>(null);
  const cityRef = useRef<SelectInstance<SelectOption> | null>(null);

  const zipCodeMinLenght = useMemo(() => {
    const minLength = CountryCodeList.find((c) => c.countryCode === countrySelected)?.zipCodeLength || 5;

    return minLength;
  }, [countrySelected]);

  const isLoading = subdivisionIsLoading || cityIsLoading || countryIsLoading;

  const onChangeCountry = async (value: string, clear = true) => {
    if (onSelectCountry) {
      onSelectCountry(value);
    }

    if (clear) {
      setValue("subnational", "");
      subdivRef.current?.clearValue();
    }

    const subdivisions = await getSubdivisionOptions(value, language);
    if (subdivisions && onLoadSubdivisions) {
      onLoadSubdivisions(subdivisions);
    }
  };

  const onChangeSubdivision = async (value: string, clear = true) => {
    if (clear) {
      setValue("city", "");
      cityRef.current?.clearValue();
    }
    if (value) {
      await getSubdivisionCitiesOptions(value);
    }
  };

  useEffect(() => {
    (async () => {
      if (countrySelected && language) {
        setValue("country", countrySelected);
        await onChangeCountry(countrySelected, false);

        if (subdivisionSelected) {
          setValue("subnational", subdivisionSelected);
          await onChangeSubdivision(subdivisionSelected, false);
        }

        if (citySelected) {
          setValue("city", citySelected);
        }
      }
    })();
  }, [countrySelected, subdivisionSelected, citySelected, language]);

  return (
    <>
      <Text size={2} lineHeight="24px" weight={600} align="left" margin="0 0 25px 2px">
        {t("Beneficiaries.AddressTitle")}
      </Text>

      <Controller
        control={control}
        name="country"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <SearchSelect
              errors={errors}
              placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.Country")}
              options={countriesWithoutUSAOptions}
              name="country"
              value={countriesWithoutUSAOptions.find((c) => c.value === value)}
              m="0 0 25px 0"
              showAvatar
              showAvatarOnValue
              onBlur={onBlur}
              onChange={(sel) => {
                if (sel?.value) {
                  onChange(sel.value);
                  onChangeCountry(sel.value);
                }
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name="subnational"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <SearchSelect
              selectRef={subdivRef}
              errors={errors}
              placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.State")}
              options={subdivisionList}
              name="subnational"
              placeholder=""
              value={subdivisionList.find((s) => s.value === value)}
              m="0 0 25px 0"
              onBlur={onBlur}
              onChange={(sel) => {
                onChange(sel?.value);
                onChangeSubdivision(sel?.value);
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name="city"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <SearchSelect
            errors={errors}
            selectRef={cityRef}
            placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.City")}
            name="city"
            placeholder=""
            options={citiesList}
            m="0 0 25px 0"
            value={citiesList.find((s) => s.value === value)}
            onBlur={onBlur}
            onChange={(sel) => onChange(sel?.value || "")}
          />
        )}
      />

      <FormInput
        placeholderInput={
          countrySelected && ["MEX", "GTM"].includes(countrySelected)
            ? t("Beneficiaries.CreateBeneficiary.Form.AddressStreet")
            : t("Beneficiaries.CreateBeneficiary.Form.Address1")
        }
        label="address1"
        rules={{
          required: t("Forms.required"),
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
        }}
        mb="25px"
        isError={Boolean(errors?.["address1"])}
      />

      <FormInput
        placeholderInput={
          countrySelected && ["MEX", "GTM"].includes(countrySelected)
            ? t("Beneficiaries.CreateBeneficiary.Form.AddressApartment")
            : t("Beneficiaries.CreateBeneficiary.Form.Address2")
        }
        label="address2"
        rules={{}}
        mb="25px"
      />

      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.PostalCode")}
        maxLength={zipCodeMinLenght}
        label="postalCode"
        rules={{
          required: t("Forms.required"),
          minLength: {
            value: zipCodeMinLenght,
            message: t("Beneficiaries.CreateBeneficiary.Form.PostalCodeMinLength", {
              minLength: zipCodeMinLenght,
            }),
          },
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
        }}
        isError={Boolean(errors?.["postalCode"])}
      />
    </>
  );
};
