const sizes = {
  12: "4.5rem",
  11: "4rem",
  10: "3.5rem",
  9: "3rem",
  8: "2.75rem",
  7: "2.5rem",
  6: "2.25rem",
  5: "2rem",
  4: "1.75rem",
  3: "1.5rem",
  2: "1.25rem",
  1: "1rem",
  0.5: "0.875rem",
  0.3: "0.75rem",
  0.2: "0.525rem",
  0.1: "0.625rem",
  0: "1.125rem",
  default: "0.75rem",
};

export type SizesKey = keyof typeof sizes;

export const handleSizeType = (size?: SizesKey) => {
  return size || size === 0 ? sizes[size] : sizes["default"];
};
