import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeneficiarySelector, BeneficiarySlice } from "../../../Redux/Beneficiary/Beneficiary.slice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../Hooks/useModal";
import Layout from "../../../Components/Layout";
import { ThemeProvider } from "styled-components";
import theme from "../../../Contexts/ThemeProvider";
import { scrollTopWindow } from "../../../Utilities/ScrollTopWindow";
import { Column, Container, Row } from "../../../Components/Grid";
import { FieldValues, FormProvider, UseFormReturn, useForm } from "react-hook-form";
import Button from "../../../Components/Button";
import { useBeneficiaryAdditionalFields } from "../../../Hooks/useBeneficiaryAdditionalFields";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { DynamicInput } from "../../../Components/DynamicInput";
import isEmpty from "lodash.isempty";
import { getStatusPersonKYC, PersonsKYCSelector } from "../../../Redux/PersonsKYC";
import { AdditionalFieldInfo } from "../../../Modules/Beneficiary/domain/BeneficiaryForm";
import { AppDispatch } from "../../../Redux/Store";
import { GET_PERSON_ID_FULFILLED } from "../../../Constants/Person";
import { BeneficiaryEdit } from "../../../Modules/Beneficiary/domain/Beneficiary";
import { useBeneficiary } from "../../../Hooks/useBeneficiary";
import { BeneficiaryEditHead } from "../Components/BeneficiaryEditHead/BeneficiaryEditHead.component";
import { AdditionalField } from "../../../Modules/Common/domain/AdditionalFields";
import { getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input/input";
import { DashboardSelector } from "../../../Redux/Dashboard/Dashboard.slice";
import { ProfileForm } from "../Components/ProfileForm/ProfileForm.component";
import { ContactForm } from "../Components/ContactForm/ContactForm.component";
import { AddressForm } from "../Components/AddressForm/AddressForm.component";

export const EditBeneficiary = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [beneficiary, setBeneficiary] = useState<BeneficiaryEdit>();
  const {
    data: { countrySelected: beneficiaryCountrySelected },
  } = useSelector(BeneficiarySelector);
  const {
    data: { countryDestination: quoteCountrySelected, currentPaymentDestination },
  } = useSelector(QuotationSelector);
  const { data: personKYCData } = useSelector(PersonsKYCSelector);
  const currentBeneficiarySelected = useSelector(BeneficiarySelector).data?.beneficiaryEdit;

  const [countrySelected, setCountrySelected] = useState(
    beneficiaryCountrySelected?.countryCode || quoteCountrySelected
  );
  const [subdivisionSelected, setSubdivisionSelected] = useState<string>();
  const [citySelected, setCitySelected] = useState<string>();
  const [phoneCountryCodeSelect, setPhoneCountryCodeSelect] = useState<string>();

  const { modal, showModal, setOpenModal } = useModal();
  const form = useForm<any>({ mode: "onSubmit", values: beneficiary });
  const {
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    getValues,
    trigger,
  } = form;

  const { previousView } = useSelector(DashboardSelector);
  const {
    additionalFieldsAppriza,
    fetchAdditionalFieldsAppriza,
    isLoading: loadingAdditionalAppriza,
  } = useBeneficiaryAdditionalFields({
    country: "",
  });
  const {
    beneficiaryInformationId,
    isLoading: loadingBeneficiary,
    getBeneficiaryInformation,
    deleteBeneficiarySelected,
  } = useBeneficiary({ form: form as unknown as UseFormReturn<FieldValues> });

  const { additionalFields: beneficiaryAdditionalFields, isLoading: loadingAdditionalFields } =
    useBeneficiaryAdditionalFields({
      country: countrySelected || "",
      destinationSelected: currentPaymentDestination,
      transferType: "CPU",
      includeBranchFields: false,
      form: form as unknown as UseFormReturn<FieldValues>,
    });

  const loading = loadingAdditionalFields || loadingAdditionalAppriza || loadingBeneficiary;

  const beneficiaryInformation = async () => {
    if (beneficiaryInformationId) {
      const countryPhone = parsePhoneNumber(beneficiaryInformationId.phoneNumber);
      const phoneNumber = countryPhone?.nationalNumber;
      if (countryPhone?.country) {
        setValue("phoneNumber", phoneNumber);
        const strPhoneCode = `+${getCountryCallingCode(countryPhone.country)}`;
        setPhoneCountryCodeSelect(countryPhone.country);
        setValue("phoneCountryCode", strPhoneCode);
      }
      setBeneficiary({
        ...beneficiaryInformationId,
        phoneNumber: phoneNumber || beneficiaryInformationId.phoneNumber,
      });
      const date = beneficiaryInformationId.dateOfBirth.split("-");
      if (date.length === 3) {
        setValue("dateOfBirth", `${date[2]}/${date[1]}/${date[0]}`, {
          shouldValidate: true,
        });
      }
      setCountrySelected(beneficiaryInformationId?.country);
      setValue("country", beneficiaryInformationId.country, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setSubdivisionSelected(beneficiaryInformationId.subnational);
      setCitySelected(beneficiaryInformationId.city);
      setValue("subdivision", beneficiaryInformationId.subnational, { shouldTouch: true });
      setValue("city", beneficiaryInformationId.city, { shouldTouch: true });
      setValue("address1", beneficiaryInformationId.address, { shouldTouch: true });
      setValue("address2", beneficiaryInformationId.addressExtra, { shouldTouch: true });
    }
  };

  const getBeneficiaryId = async () => {
    if (currentBeneficiarySelected?.beneficiaryId) {
      await getBeneficiaryInformation(currentBeneficiarySelected.beneficiaryId);
    }
  };

  const handleDeleteBeneficiary = () => {
    if (beneficiary) {
      showModal({
        modalType: "confirmationModal",
        title: t("Beneficiaries.EditBeneficiary.titleModalDelete"),
        message: t("Beneficiaries.EditBeneficiary.subTitleModalDelete"),
        handleConfirm: () => {
          deleteBeneficiary(beneficiary?.beneficiaryId);
        },
      });
    }
  };

  const deleteBeneficiary = async (beneficiaryId: string) => {
    if (beneficiaryId) {
      await deleteBeneficiarySelected(beneficiaryId);
      setOpenModal(true);
      navigate("/dashboard", {
        state: { activeTab: "recipients" },
      });
    }
  };

  const getAdditionalFieldDefault = (field: AdditionalField) => {
    return (
      beneficiary?.additionalField?.additionalFieldInfo &&
      beneficiary?.additionalField?.additionalFieldInfo.find(
        (additionalField: AdditionalFieldInfo) => additionalField.fieldName === field.fieldName.split(".").reverse()[0]
      )?.fieldValue
    );
  };

  const processSubmit = handleSubmit(async (data) => {
    trigger();
    let personId = personKYCData?.personVeriff?.personId;
    if (!personId) {
      const userAuthPerson = await dispatch(getStatusPersonKYC());
      if (userAuthPerson.type === GET_PERSON_ID_FULFILLED) {
        personId = userAuthPerson.payload?.toString();
      }
    }

    const date = data.dateOfBirth.split("/");

    if (personId) {
      const formData = {
        ...data,
        email: data.email ? data.email.trim() : null,
        phoneNumber: `${data.phoneCountryCode}${data.phoneNumber}`,
        dateOfBirth: `${date[2]}-${date[1]}-${date[0]}`,
        address: data.address1,
        addressExtra: data.address2,
      };

      formData.additionalField = {
        additionalFieldInfo:
          additionalFieldsAppriza.map((field) => ({
            fieldName: field.fieldName,
            fieldValue: String(getValues(`${field.fieldName}`)),
            fieldBelongsTo: field.fieldBelongsTo,
          })) || [],
      };

      formData.accounts = beneficiary?.accounts;

      dispatch(
        BeneficiarySlice.actions.setBeneficiaryEdit({
          ...formData,
        })
      );
      navigate("/accounts", {
        state: {
          view: "edit",
        },
      });
    } else {
      navigate(-1);
    }
  });

  useEffect(() => {
    if (countrySelected) {
      fetchAdditionalFieldsAppriza(countrySelected);
    }
  }, [countrySelected]);

  useEffect(() => {
    scrollTopWindow();
    getBeneficiaryId();
  }, []);

  useEffect(() => {
    const phoneNumber = currentBeneficiarySelected?.phoneNumber;
    if (phoneNumber) {
      const countryPhone = parsePhoneNumber(phoneNumber);
      if (countryPhone && countryPhone.country) {
        setValue("phoneNumber", countryPhone.nationalNumber);
        const strPhoneCode = `+${getCountryCallingCode(countryPhone.country)}`;
        setValue("phoneCountryCode", strPhoneCode);
      }
    }
  }, [phoneCountryCodeSelect]);

  useEffect(() => {
    (async () => {
      if (beneficiaryInformationId && !beneficiary) {
        await beneficiaryInformation();
      }
    })();
  }, [beneficiaryInformationId]);

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={loading} roundedContent={false}>
        <FormProvider {...form}>
          <form style={{ width: "100%", maxWidth: "393px", margin: "auto" }} onSubmit={processSubmit}>
            <Container maxWidth="393px">
              <Row>
                <Column span={12} px={3} mt={2} mb={3}>
                  <BeneficiaryEditHead
                    onHandleDelete={() => handleDeleteBeneficiary()}
                    onClose={() =>
                      navigate("/dashboard", {
                        state: {
                          activeTab: previousView === "dashboard" ? "dashboard" : "recipients",
                        },
                      })
                    }
                    showDelete={beneficiary?.accounts?.length === 0}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <ProfileForm />
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <ContactForm countrySelected={countrySelected} />
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <AddressForm
                    countrySelected={countrySelected}
                    subdivisionSelected={subdivisionSelected}
                    citySelected={citySelected}
                    onSelectCountry={setCountrySelected}
                  />
                </Column>
              </Row>
              {!isEmpty(beneficiaryAdditionalFields) && (
                <Row>
                  <Column span={12} px={3} mb={2}>
                    <hr />
                  </Column>
                  <Column span={12} px={3} mb={2}>
                    {beneficiaryAdditionalFields.map((field) => (
                      <DynamicInput key={field.fieldName} {...field} />
                    ))}
                  </Column>
                </Row>
              )}
              {!isEmpty(additionalFieldsAppriza) && (
                <Row>
                  <Column span={12} px={3} mb={2}>
                    <hr />
                  </Column>
                  <Column span={12} px={3} mb={2}>
                    {additionalFieldsAppriza.map((field) => (
                      <DynamicInput key={field.fieldName} {...field} defaultValue={getAdditionalFieldDefault(field)} />
                    ))}
                  </Column>
                </Row>
              )}
              <Row>
                <Column span={12} px={3} mb={4}>
                  <Button
                    variant="primary"
                    sizeButton="full"
                    text={t("global.continue")}
                    sizeText="medium"
                    iconButton="arrowRight"
                    colorIcon="white"
                    sizeIcon="large"
                    padding="8px"
                    type="submit"
                  />
                </Column>
              </Row>
            </Container>
          </form>
        </FormProvider>
        {modal}
      </Layout>
    </ThemeProvider>
  );
};
