import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Own models
import { AlignItems, JustifyContent } from "../../Models/Column";

// Own utils
import { CopyTextToClipboard } from "../../Utilities/CopyTextToClipboard";
import { DifferenceTime } from "../../Utilities/DifferenceTime";

// Own redux
import { updateVenmoModal } from "../../Redux/VenmoModal";
import { AppDispatch } from "../../Redux/Store";

// Own components
import { Column, Row } from "../Grid";
import Text from "../Text";
import Icon from "../Icon";
import { VenmoTransactionCard } from "./index";

// Own styles
import { ArrowBack, RowIntructions, RowSteps, TextCopy } from "./VenmoTransaction.styled";

// Own assets
import VenmoLogo from "../../Assets/Img/venmo-wide-logo.png";
import PaymentStep1 from "../../Assets/Img/zelle-step-1.png";
import PaymentStep2 from "../../Assets/Img/Zelle-step-2.png";
import PaymentStep3 from "../../Assets/Img/Zelle-step-3.png";
import { PaymentTransactionHistory } from "../../Modules/PaymentOrders/domain/PaymentOrder";

export interface CopyToClipboardComponentProps {
  copyText: string;
}

export interface PropsVenmoTransactionComponent {
  transactionList?: PaymentTransactionHistory | null;
  showTransactionInstructions?: (show: boolean) => void;
}

const CopyToClipboardComponent = (props: CopyToClipboardComponentProps) => {
  const [t] = useTranslation("global");
  const { copyText } = props;
  const [iconCopy, setIconCopy] = useState<boolean>(true);

  const CopyToClipboard = () => {
    CopyTextToClipboard(copyText);
    setIconCopy(false);
    setTimeout(() => {
      setIconCopy(true);
    }, 1000);
  };

  return (
    <TextCopy onClick={CopyToClipboard}>
      <Icon icon={iconCopy ? "copy" : "check"} color="Primary01" size="small" />
      <Text size={0.3} color="Primary01" align="center" weight="600" margin="0 4px">
        {t("VenmoTransactionsModal.instruction.copy")}
      </Text>
    </TextCopy>
  );
};

const VenmoTransaction = (props: PropsVenmoTransactionComponent) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const FloatingBtn = useRef<HTMLDivElement>(null);
  const { transactionList, showTransactionInstructions } = props;
  const [showInstructions, setShowInstructions] = useState<boolean>(false);
  const [amountSelected, setAmountSelected] = useState<number>(0);
  const [referenceSelected, setReferenceSelected] = useState<string>("");
  const transactionsVenmo =
    transactionList?.results?.filter(
      (transaction) =>
        transaction.fundType === "Venmo" && transaction.status === "Funds" && DifferenceTime(transaction?.processedAt)
    ) || [];

  const selectedData = (amount: number, reference: string) => {
    setAmountSelected(amount);
    setReferenceSelected(reference);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (FloatingBtn.current && !FloatingBtn.current?.contains(event.target as Node)) {
        dispatch(updateVenmoModal(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [FloatingBtn]);

  return (
    <div>
      {!showInstructions && (
        <div>
          <Row>
            {transactionsVenmo?.map((transaction: any) => {
              return (
                <VenmoTransactionCard
                  key={transaction.mtcn}
                  dateAt={transaction.processedAt}
                  userName={transaction.contactName}
                  amount={transaction.amountFound}
                  countrySender={transaction.countrySender}
                  destinationCountry={transaction.destinationCountry}
                  mtcn={transaction.mtcn}
                  selectedData={selectedData}
                  setShowInstructions={(show: boolean) => {
                    setShowInstructions(show);

                    if(showTransactionInstructions) {
                      showTransactionInstructions(show);
                    }
                  }}
                />
              );
            })}
          </Row>
        </div>
      )}

      {showInstructions && (
        <div>
          <Row>
            <ArrowBack
              onClick={() => {
                setShowInstructions(false);
                
                if(showTransactionInstructions) {
                  showTransactionInstructions(false);
                }
              }}
            >
              <Icon icon="arrowLeft" color="black" size="medium" />
            </ArrowBack>
          </Row>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} alignItems={AlignItems.center}>
              <Text size={2} color="venmo" align="center" weight="600" margin="0">
                <span dangerouslySetInnerHTML={{ __html: t("VenmoTransactionsModal.instruction.title") }} />
              </Text>
              &nbsp;
              <img src={VenmoLogo} width="66px" height="14px" style={{ display: "inline", margin: "0 4px" }} />
            </Column>
            <Column span={12} justifyContent={JustifyContent.center}>
              <Text size={1} color="black" align="center" weight="500" margin="0">
                {t("VenmoTransactionsModal.instruction.subTitle")}
              </Text>
            </Column>
          </Row>
          <Row as={RowIntructions}>
            <Column span={12}>
              <Row as={RowSteps}>
                <Column span={2}>
                  <img src={PaymentStep1} width="32px" height="32px" />
                </Column>
                <Column span={8} alignItems={AlignItems.center}>
                  <Text size={0.3} color="black" align="left" weight="600" margin="0">
                    <span dangerouslySetInnerHTML={{ __html: t("VenmoTransactionsModal.instruction.step1") }} />
                  </Text>
                </Column>
                <Column span={2} alignItems={AlignItems.center}>
                  <CopyToClipboardComponent copyText="@sendola" />
                </Column>
              </Row>

              <Row as={RowSteps}>
                <Column span={2}>
                  <img src={PaymentStep2} width="32px" height="32px" />
                </Column>
                <Column span={8} alignItems={AlignItems.center}>
                  <Text size={0.3} color="black" align="left" weight="600" margin="0">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("VenmoTransactionsModal.instruction.step2", { totalAmount: amountSelected }),
                      }}
                    />
                  </Text>
                </Column>
                <Column span={2} alignItems={AlignItems.center}>
                  <CopyToClipboardComponent copyText={`${amountSelected}`} />
                </Column>
              </Row>

              <Row as={RowSteps}>
                <Column span={2}>
                  <img src={PaymentStep3} width="32px" height="32px" />
                </Column>
                <Column span={8} alignItems={AlignItems.center}>
                  <Text size={0.3} color="black" align="left" weight="600" margin="0">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("VenmoTransactionsModal.instruction.step3", { reference: referenceSelected }),
                      }}
                    />
                  </Text>
                </Column>
                <Column span={2} alignItems={AlignItems.center}>
                  <CopyToClipboardComponent copyText={referenceSelected} />
                </Column>
              </Row>
            </Column>
          </Row>
        </div>
      )}
    </div>
  );
};

export default VenmoTransaction;
